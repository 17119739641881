import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`This API is used to send a message to an existing contact using various delivery channels like SMS, Telegram, etc.`}</p>
    <p>{`The main "rule" of this API is:
The more parameters you use, the more precisely you can control the delivery.`}</p>
    <p>{`The http requests consists out of the following main parts`}</p>
    <ul>
      <li parentName="ul">{`Authentication`}<ul parentName="li">
          <li parentName="ul">{`combination of username and password`}</li>
        </ul></li>
      <li parentName="ul">{`Contact identification`}<ul parentName="li">
          <li parentName="ul">{`unique identification of one of your existing contacts`}</li>
        </ul></li>
      <li parentName="ul">{`Content`}<ul parentName="li">
          <li parentName="ul">{`the message content `}</li>
        </ul></li>
      <li parentName="ul">{`Delivery channel details`}<ul parentName="li">
          <li parentName="ul">{`optional details of how to deliver the message`}</li>
          <li parentName="ul">{`example:`}<ul parentName="li">
              <li parentName="ul">{`send via Telegram (or any other preferred delivery channel) if possible, else use a different channel`}</li>
              <li parentName="ul">{`send only via SMS`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "send-general"
    }}>{`Send general`}</h2>
    <p>{`To keep it as simple as possible we accept GET and POST requests.`}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://app.anymessage.cloud/api/initiate/message"
      }}>{`https://app.anymessage.cloud/api/initiate/message`}</a></p>
    <h3 {...{
      "id": "authorization"
    }}>{`Authorization`}</h3>
    <p>{`Each request needs an authorization header with an valid bearer token. The token can be generated using the `}<a parentName="p" {...{
        "href": "https://app.anymessage.cloud"
      }}>{`anymessage webapp`}</a>{`.`}</p>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contactId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification of the contact you want to send the message to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`contact-XYZ123`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message you want to send`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hello world`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentHeadline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional headline for the message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hi john`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deliveryChannelId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the preferred delivery channel. If omitted we choose a delivery channel based on the contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sms`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deliveryTarget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used if a contact has more than one option for a delivery channel (e.g. two sms destinations). Submit the target id of the contact destination to force a delivery via this target`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sms-1234abc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deliveryForced`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only valid with deliveryChannelId or deliveryTarget, Set it to 1 to force the sending via the given delivery channel or delivery target. (default = 0)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dlr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set it to 1 if you want to receive a DLR. Note: you need to provide a URL to receive the DLRs first. If you don't want DLRs, set it to 0. (default = 0)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <p>{`In this example we assume that the parameters contactId, contentText and contentHeadline as well as the authorization header are set correctly.`}</p>
    <ol>
      <li parentName="ol">{`Send a message to a contact regardless of the delivery channel`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`no further parameters are required`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Send message via a preferred delivery channel. If this is not possible, then try to send the message via other delivery channels`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Add the following additional optional parameters `}<ul parentName="li">
          <li parentName="ul">{`deliveryChannelId`}</li>
        </ul></li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Send a message via one delivery channel only`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Add the following additional optional parameters `}<ul parentName="li">
          <li parentName="ul">{`deliveryChannelId`}</li>
          <li parentName="ul">{`deliveryForced`}</li>
        </ul></li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Send a message via a specific delivery target only `}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Add the following additional optional parameters `}<ul parentName="li">
          <li parentName="ul">{`deliveryTarget`}</li>
          <li parentName="ul">{`deliveryForced `}</li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h2 {...{
      "id": "send-sms-message"
    }}>{`Send SMS Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a SMS message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "sms":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via SMS if possible`}</li>
      <li parentName="ul">{`If the contact has more than one SMS destinations (deliveryTargets) the message is sent via the preferred sms destination (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via SMS is not possible, the message is sent out using a different channel e.g. Twitter, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "sms" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via SMS if possible`}</li>
      <li parentName="ul">{`If the contact has more than one SMS destinations the message is sent via the preferred sms destination`}</li>
      <li parentName="ul">{`If a delivery via SMS is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the SMS destination of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. Twitter `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the SMS destination of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-telegram-message"
    }}>{`Send Telegram Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a SMS message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "tel":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via Telegram if possible`}</li>
      <li parentName="ul">{`If the contact has more than one Telegram subscriptions (deliveryTargets) the message is sent via the preferred Telegram subscriptions (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via Telegram is not possible, the message is sent out using a different channel e.g. Twitter, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "tel" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via Telegram if possible`}</li>
      <li parentName="ul">{`If the contact has more than one Telegram subscriptions the message is sent via the preferred Telegram subscription`}</li>
      <li parentName="ul">{`If a delivery via Telegram is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Telegram subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. Twitter `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Telegram subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-meta--facebook-message"
    }}>{`Send Meta / Facebook Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a Meta message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "met":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via Meta if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions (deliveryTargets) to Meta the message is sent via the preferred subscription (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via Meta is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "met" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via Meta if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions to Meta the message is sent via the preferred subscription`}</li>
      <li parentName="ul">{`If a delivery via Meta is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Meta subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. SMS `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Meta subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-signal-message"
    }}>{`Send Signal Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a Signal message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "sig":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via Signal if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions (deliveryTargets) to Signal the message is sent via the preferred subscription (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via Signal is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "sig" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via Signal if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions to Signal the message is sent via the preferred subscription`}</li>
      <li parentName="ul">{`If a delivery via Signal is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Signal subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. SMS `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Signal subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-instagram-business-message"
    }}>{`Send Instagram Business Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a Instagram message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "ins":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via Instagram if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions (deliveryTargets) to Instagram the message is sent via the preferred subscription (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via Instagram is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "ins" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via Instagram if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions to Instagram the message is sent via the preferred subscription`}</li>
      <li parentName="ul">{`If a delivery via Instagram is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Instagram subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. SMS `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Instagram subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-whatsapp-business-message"
    }}>{`Send WhatsApp Business Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a WhatsApp message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "wha":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via WhatsApp if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions (deliveryTargets) to WhatsApp the message is sent via the preferred subscription (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via WhatsApp is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "wha" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via WhatsApp if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions to WhatsApp the message is sent via the preferred subscription`}</li>
      <li parentName="ul">{`If a delivery via WhatsApp is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the WhatsApp subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. SMS `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the WhatsApp subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-twitter-message"
    }}>{`Send Twitter Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a Twitter message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "twi":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via Twitter if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions (deliveryTargets) to Twitter the message is sent via the preferred subscription (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via Twitter is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "twi" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via Twitter if possible`}</li>
      <li parentName="ul">{`If the contact has more than one subscriptions to Twitter the message is sent via the preferred subscription`}</li>
      <li parentName="ul">{`If a delivery via Twitter is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Twitter subscriptions of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. SMS `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the Twitter subscriptions of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <h2 {...{
      "id": "send-tts-text-to-speech-message"
    }}>{`Send TTS (Text-To-Speech) Message`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "send_to_contact_introduction/#send-general"
      }}>{`send general section`}</a>{` first to learn how to send a request.
In order to send a TTS message you only need to adapt a few parameters.
See the following list:`}</p>
    <ol>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "tts":`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via TTS if possible`}</li>
      <li parentName="ul">{`If the contact has more than one TTS destinations (deliveryTargets) the message is sent via the preferred destination (deliveryTarget)`}</li>
      <li parentName="ul">{`If a delivery via TTS is not possible the message is sent out using a different channel e.g. SMS, based on the contact's information`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the parameter deliveryChannelId to "tts" and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via TTS if possible`}</li>
      <li parentName="ul">{`If the contact has more than one TTS destinations the message is sent via the preferred destination`}</li>
      <li parentName="ul">{`If a delivery via TTS is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the TTS destination of the contact:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message is sent out using a different channel e.g. Twitter `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Change the parameter deliveryTarget. Use one of the TTS destination of the contact and set parameter deliveryForced to 1:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The API tries to send the message only via the deliveryTarget if possible`}</li>
      <li parentName="ul">{`If a delivery via this target is not possible the message will not be sent via a different channel and remain undelivered`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      